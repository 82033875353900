/* For use in src/lib/core/theming/_palette.scss */
/* Style 500 is default */
@import '~@angular/material/theming';

// Be sure that you only ever include 'mat-core' mixin once!
// it should not be included for each theme.
@include mat-core(); 

$md-primary: (
    50 : #fcf8ed,
    100 : #f8ecd2,
    200 : #f4e0b5,
    300 : #f0d497,
    400 : #ecca80,
    500 : #e9c16a,
    600 : #e6bb62,
    700 : #e3b357,
    800 : #dfab4d,
    900 : #d99e3c,
    A100 : #ffffff,
    A200 : #fffaf3,
    A400 : #ffe6c0,
    A700 : #ffdca7,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-secundary: (
    50 : #ecf4f5,
    100 : #d1e3e6,
    200 : #b2d1d6,
    300 : #93bec6,
    400 : #7bb0b9,
    500 : #64a2ad,
    600 : #5c9aa6,
    700 : #52909c,
    800 : #488693,
    900 : #367583,
    A100 : #d1f6ff,
    A200 : #9eecff,
    A400 : #6be3ff,
    A700 : #52deff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-tertiary: (
    50 : #e6ebf5,
    100 : #c0cce6,
    200 : #96aad5,
    300 : #6c88c4,
    400 : #4d6fb7,
    500 : #2d55aa,
    600 : #284ea3,
    700 : #224499,
    800 : #1c3b90,
    900 : #112a7f,
    A100 : #b3c2ff,
    A200 : #8099ff,
    A400 : #4d70ff,
    A700 : #335bff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);


// mandatory stuff for theming
$md-palette-primary: mat-palette($md-primary);
$md-palette-secundary:  mat-palette($md-secundary);
$md-palette-tertiary:  mat-palette($md-tertiary);

// include the custom theme components into a theme object
$md-theme: mat-light-theme($md-palette-primary, $md-palette-secundary, $md-palette-tertiary);
