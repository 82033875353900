/*  
    Kleurenpalet (https://i.pinimg.com/originals/c7/57/55/c75755f11f00edb3d030cc29e227caec.jpg): 
    - Rood-bruin:   #B84F0B
    - Geel:         #E9C16A
    - Bruin:        #D3C199
    - Lichtblauw:   #A2D5D8
    - Blauw:        #64A2AD

    Kleur tekst voor op de oranje basis kleur: #225964

    Boven = Oud, Nieuw:

    Nieuwe basis kleuren:
    - Oranje:           #E9C16A
    - Lichtblauw:       #64A2AD
    - Donkerblauw:      #2D55AA

    Kleur tekst voor op de oranje basis kleur: #225964
*/

@import '~@angular/material/theming';
@import 'variables.scss';

// include the custom theme object into the angular material theme
@include angular-material-theme($md-theme);


.btn-outline-light {
    border-color: mat-color($md-palette-primary, 500) !important;
    color: #225964 !important;
    background-color: mat-color($md-palette-primary, 500) !important;
  }
.btn-outline-light:hover {
    color: #225964 !important;
    background-color: mat-color($md-palette-primary, 300) !important;
    border-color: mat-color($md-palette-primary, 300) !important;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
    box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
    color: #225964;
    background-color: mat-color($md-palette-primary, 500) !important;
    border-color: mat-color($md-palette-primary, 500) !important;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
    color: mat-color($md-palette-primary, 500) !important;
    background-color: transparent;
}

  ::selection {
    color: white !important;
    background: mat-color($md-palette-secundary, 700) !important;
  }